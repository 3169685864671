import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import {sentenceCase} from 'change-case';
import {filter} from 'lodash';
import {useContext, useState} from 'react';
import {styled} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import {ArrowDownwardOutlined, ArrowUpwardOutlined} from "@mui/icons-material";
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import {TransactionListHead, TransactionListToolbar, TransactionMoreMenu} from '../sections/@dashboard/transaction';
import Scrollbar from '../components/Scrollbar';
import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
import AccountBalanceView from '../components/AccountBalanceView';
import {TransactionContext} from '../context/CryptoNdani';
import StatusIcon from '../components/StatusIcon';
import Dialog from '../components/Dialog';
import LoadingDialog from '../components/LoadingDialog';
import errorHandler from '../utils/errorHandler';
import shortenAddress from "../utils/shortenAddress";
import AnimdatedCard from "../components/AnimdatedCard";

const TABLE_HEAD = [
  {id: 'recepient', label: 'Recipient Address', alignRight: false},
  {id: 'sender', label: 'Sender', alignRight: false},
  {id: 'amount', label: 'Amount', alignRight: false},
  {id: 'status', label: 'Status', alignRight: false},
  {id: ''},
];

const STATUSES_MAP = {
  1: 'Pending',
  2: 'Completed',
  3: 'Cancelled',
};
const STATUS_BADGE_COLOR_TYPES_MAP = {
  1: 'warning',
  2: 'success',
  3: 'error',
};

const StyledTypography = styled(Typography)(({theme}) => ({
  color: theme.palette.secondary.main,
  fontWeight: 700,
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(2),
}));

const StyledAlert = styled(Alert)(({theme}) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledAvatar = styled(Avatar)(({theme}) => ({
  marginLeft: theme.spacing(2),
}));

const MoneySentAvatar = styled(Avatar)(({variant}) => ({
  backgroundColor: variant === "sent" ? "#81d4fa" : "#c8e6c9"
}));

const MoneySentIcon = styled(Avatar)(({variant}) => ({
  color: variant === "sent" ? "#0277bd" : "#2e7d32"
}));

// style constant
const useStyles = makeStyles(() => ({
  signDivider: {
    flexGrow: 1,
  },
  dialogContent: {
    textAlign: 'center',
  },
  loginInput: {
    marginTop: 8,
    marginBottom: 8,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.recepient.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

const Transactions = () => {
  const classes = useStyles();

  const {accountInfo, accountPayments, errors, refreshAccountBalance, isLoading} = useContext(TransactionContext);
  console.log(accountInfo)

  const [showBalanceOpen, setShowBalanceOpen] = useState(true);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('status');

  const [filterTransaction, setFilterTransaction] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [transactionDetails, setTransactionDetails] = useState({
    transactionStateDialog: false,
    transactionStatus: false,
    message: '',
  });

  const {transactionStateDialog, transactionStatus, message} = transactionDetails;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = accountPayments?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByTransaction = (event) => {
    setFilterTransaction(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - accountPayments?.length) : 0;

  const filteredPayments = applySortFilter(accountPayments, getComparator(order, orderBy), filterTransaction);
  console.log(filteredPayments)

  const isTransactionNotFound = filteredPayments?.length === 0;

  const closeDialog = () => {
    setTransactionDetails({transactionStateDialog: false, transactionStatus: false, message: ''});
  };

  return (
    <Page title="Transactions">
      <Dialog
        open={transactionStateDialog}
        modalContent={
          <Box className={classes.dialogContent}>
            <StatusIcon
              status={transactionStatus ? 'success' : 'error'}
              text={transactionStatus ? 'Transaction successful' : 'Transaction failed'}
            />
            <Typography variant="body1"> {message}</Typography>
          </Box>
        }
        modalActions={
          <Button variant="contained" onClick={() => closeDialog()} color="primary" autoFocus>
            Close
          </Button>
        }
        handleClose={closeDialog}
      />
      <LoadingDialog isLoading={isLoading}/>
      <Container maxWidth="xl">
        <StyledTypography variant="h3">Crypto.Ndani</StyledTypography>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Transactions
          </Typography>
          <Button variant="contained" component={RouterLink} to="/send" startIcon={<Iconify icon="eva:plus-fill"/>}>
            Send Crypto
          </Button>
        </Stack>
        {!accountInfo?.isWalletConnected &&
          <StyledAlert severity="error">{errorHandler('DISCONNECT_ERROR')}</StyledAlert>}
        {/* Take care of disconnection */}
        {errors?.connection &&
          (accountInfo?.isWalletConnected ? (
            <StyledAlert severity="error">{errorHandler('DISCONNECT_ERROR')}</StyledAlert>
          ) : (
            <StyledAlert severity="error">{errorHandler(errors?.connection)}</StyledAlert>
          ))}
        <AccountBalanceView
          showBalanceOpen={showBalanceOpen}
          setShowBalanceOpen={setShowBalanceOpen}
          hasError={errors?.balance}
          refreshAccountBalance={refreshAccountBalance}
          accountInfo={accountInfo}
        />
        <Typography sx={{color: 'text.secondary', mb: 5, mt: 2}}>
          Provide address information below to send Crypto to your intended recipient. This is a secure process that
          involves authentication to your Wallet
        </Typography>

        <AnimdatedCard>
          <TransactionListToolbar
            numSelected={selected?.length}
            filterName={filterTransaction}
            onFilterName={handleFilterByTransaction}
          />
          <Scrollbar>
            <TableContainer sx={{minWidth: 800}}>
              <Table>
                <TransactionListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={accountPayments?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredPayments?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                    const {id, recepient, sender, status: statusRaw, amount, token} = row;

                    const status = STATUSES_MAP[statusRaw];

                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <StyledAvatar src={token.icon}/>
                            <Typography variant="subtitle2" noWrap>
                              {shortenAddress(recepient)}
                            </Typography>
                            <MoneySentAvatar
                              variant={recepient === accountInfo.account ? "received" : "sent"}>{recepient === accountInfo.account ?
                              <ArrowDownwardOutlined sx={{color: "#2e7d32"}}/> :
                              <ArrowUpwardOutlined sx={{color: "#0277bd"}}/>}</MoneySentAvatar>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{shortenAddress(sender)}</TableCell>
                        <TableCell align="left">{amount} {token.symbol}</TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={STATUS_BADGE_COLOR_TYPES_MAP[statusRaw]}>
                            {sentenceCase(status)}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <TransactionMoreMenu payment={row} setTransactionDetails={setTransactionDetails}/>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{height: 53 * emptyRows}}>
                      <TableCell colSpan={6}/>
                    </TableRow>
                  )}
                </TableBody>

                {isTransactionNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{py: 3}}>
                        <SearchNotFound searchQuery={filterTransaction}/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={accountPayments?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </AnimdatedCard>
      </Container>
    </Page>
  );
};

export default Transactions;
