import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import PropTypes from 'prop-types';
import { contractABI, contractAddress, currencies } from '../../utils/constants';
import errorHandler from '../../utils/errorHandler';
import shortenAddress from '../../utils/shortenAddress';

export const TransactionContext = React.createContext(undefined);

const TransactionsProvider = ({ children }) => {
  const { chainId: currentChainId, account, active: isWalletConnected, library, deactivate } = useWeb3React();

  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [contractOwner, setContractOwner] = useState(null);
  const [isContractOwner, setIsContractOwner] = useState(false);

  const [accountBalance, setAccountBalance] = useState(0);

  const [accountPayments, setAccountPayments] = useState([]);

  const [errors, setErrors] = useState({});

  const systemErrorMapper = (errorType, technicalMessage) => {
    if (isWalletConnected) {
      setErrors({ connection: 'DISCONNECT_ERROR' });
    }
    setErrors({ [errorType]: technicalMessage });
  };

  const createEthereumContract = () => {
    const provider = new ethers.providers.Web3Provider(library.provider);
    const signer = provider.getSigner();
    return new ethers.Contract(contractAddress, contractABI, signer);
  };

  const getContractOwner = async () => {
    try {
      if (library && library.provider) {
        const transactionsContract = createEthereumContract();

        return transactionsContract.owner().then((address) => address?.toLowerCase());
      }

      systemErrorMapper('connection', 'MISSING_WALLET');
      return null;
    } catch (error) {
      systemErrorMapper('balance', 'ERROR_GETTING_CONTRACT_OWNER');
      return null;
    }
  };

  // eslint-disable-next-line consistent-return
  const getAccountBalance = async () => {
    try {
      if (library && library.provider) {
        return await library.provider.request({
          method: 'eth_getBalance',
          params: [account, 'latest'],
        });
      }
      systemErrorMapper('connection', 'MISSING_WALLET');
      return 0;
    } catch (error) {
      systemErrorMapper('balance', 'NO_BALANCE_TO_SHOW');
      return 0;
    }
  };

  const refreshAccountBalance = async () => {
    // console.log('refreshAccountBalance fired');
    const balanceRaw = await getAccountBalance();
    await setAccountBalance(parseInt(balanceRaw, 16) / 10 ** 18);
  };

  const transformPaymentObject = (payment) => {
    const tokenCurrency = currencies.find((currency) => currency.address === payment.token);

    return {
      ...payment,
      amount: ethers.utils.formatUnits(payment.amount, tokenCurrency.decimals),
      token: {
        name: tokenCurrency.name,
        symbol: tokenCurrency.symbol,
        icon: tokenCurrency.icon,
        address: tokenCurrency.address,
      },
    };
  };

  const getAccountPayments = async () => {
    try {
      if (library && library.provider) {
        if (isWalletConnected) {
          const transactionsContract = createEthereumContract();
          const paymentIds = await transactionsContract.getUserPaymentIds(account).then((res) =>
            res.reduce((acc, paymentId) => {
              if (!acc.includes(paymentId._hex)) {
                acc.push(paymentId._hex);
              }

              return acc;
            }, [])
          );

          return Promise.all(
            paymentIds.map((paymentId) =>
              transactionsContract
                .getPaymentById(paymentId)
                .then((payment) => transformPaymentObject({ id: paymentId, ...payment }))
            )
          );
        }
      } else {
        systemErrorMapper('connection', 'MISSING_WALLET');
      }
    } catch (error) {
      systemErrorMapper('connection', 'INTERNAL_ERROR');
      console.log(error);
    }
  };

  const refreshAccountPayments = async () => setAccountPayments((await getAccountPayments()) || []);

  const refreshAccountPayment = async (id) => {
    try {
      if (library && library.provider) {
        const transactionsContract = createEthereumContract();

        const payment = await transactionsContract
          .getPaymentById(id)
          .then((payment) => transformPaymentObject({ id, ...payment }));

        const newAccountPayments = [...accountPayments];
        const paymentIndexInArray = newAccountPayments.findIndex((accountPayment) => accountPayment.id === id);
        newAccountPayments[paymentIndexInArray] = payment;

        return setAccountPayments(newAccountPayments);
      }
      systemErrorMapper('connection', 'MISSING_WALLET');
    } catch (error) {
      systemErrorMapper('connection', 'INTERNAL_RPC_ERROR');
    }
  };

  const acceptPayment = async (id) => {
    try {
      if (library && library.provider) {
        try {
          setIsLoading(true);
          const transactionsContract = createEthereumContract();

          const tx = await transactionsContract.claim(id);

          await tx.wait();

          await refreshAccountBalance();
          await refreshAccountPayment(id);

          setIsLoading(false);

          return {
            status: true,
            transactionHash: shortenAddress(tx.hash), // transactionHash,
            err: null,
          };
        } catch (error) {
          return {
            status: false,
            transactionHash: null,
            err: error?.error?.data?.message || error.message,
          };
        }
      }

      return {
        status: false,
        transactionHash: null,
        err: errorHandler('MISSING_WALLET'),
      };
    } catch (error) {
      /**
       * Error mapped to the errorMapper: Customer Message to be shown on the UI
       * */
      // console.error(error); // debug
      return {
        status: false,
        transactionHash: null,
        err: error.message,
      };
    }
  };

  const cancelPayment = async (id) => {
    try {
      if (library && library.provider) {
        const transactionsContract = createEthereumContract();

        try {
          setIsLoading(true);
          const tx = await transactionsContract.cancel(id);
          await tx.wait();

          await refreshAccountBalance();
          await refreshAccountPayment(id);

          setIsLoading(false);

          return {
            status: true,
            transactionHash: shortenAddress(tx.hash), // transactionHash,
            err: null,
          };
        } catch (error) {
          return {
            status: false,
            transactionHash: null,
            err: error?.error?.data?.message || error.message,
          };
        }
      }

      return {
        status: false,
        transactionHash: null,
        err: errorHandler('MISSING_WALLET'),
      };
    } catch (error) {
      /**
       * Error mapped to the errorMapper: Customer Message to be shown on the UI
       * */
      // console.error(error); // debug
      return {
        status: false,
        transactionHash: null,
        err: error.message,
      };
    }
  };

  const claimProtocolFees = async () => {
    try {
      if (library && library.provider) {
        const transactionsContract = createEthereumContract();

        const tx = await transactionsContract.claimFees(
          currencies.map((currency) => currency.address),
          contractOwner || account
        );

        await tx.wait();

        await refreshAccountBalance();

        return {
          status: true,
          transactionHash: shortenAddress(tx.hash), // transactionHash,
          err: null,
        };
      }
      // systemErrorMapper('connection', 'MISSING_WALLET14');
      return {
        status: false,
        transactionHash: null, // transactionHash,
        err: errorHandler('MISSING_WALLET'),
      };
    } catch (error) {
      // systemErrorMapper('connection', 'INTERNAL_RPC_ERROR');
      return {
        status: false,
        transactionHash: null, // transactionHash,
        err: error?.error?.data?.message || error.message,
      };
    }
  };

  const sendTransactionMutation = async ({ token, recipient, amount }) => {
    try {
      if (library && library.provider) {
        const transactionsContract = createEthereumContract();
        const parsedAmount = ethers.utils.parseEther(amount);

        try {
          const [feePercent, isSenderWhitelisted, isRecipientWhitelisted] = await Promise.all([
            transactionsContract.feePercent(),
            transactionsContract.isWhitelisted(account),
            transactionsContract.isWhitelisted(recipient),
          ]);

          const fee =
            isSenderWhitelisted || isRecipientWhitelisted
              ? ethers.BigNumber.from(0)
              : parsedAmount.mul(feePercent).div(10000);

          /**
           * initiateTransaction
           *
           *       function initiatePayment(
           *            @address token,
           *            @address payable recipient,
           *            @uint256 amount
           *        )
           *
           * */

          const transactionHash = await transactionsContract.initiatePayment(token, recipient, parsedAmount, {
            gasLimit: 6000000,
            value: parsedAmount.add(fee)._hex,
          });

          // console.log(`Loading - ${transactionHash.hash}`); // debug
          setIsLoading(true);
          await transactionHash.wait();
          await refreshAccountBalance();
          await refreshAccountPayments();

          // console.log(`Success - ${transactionHash.hash}`); // debug
          setIsLoading(false);

          return {
            status: true,
            transactionHash: shortenAddress(transactionHash.hash), // transactionHash,
            err: null,
          };
        } catch (error) {
          return {
            status: false,
            transactionHash: null,
            err: error?.error?.data?.message || error.message,
          };
        }
      }
      /**
       * Error mapped to the errorMapper: Customer Message to be shown on the UI
       * */
      systemErrorMapper('connection', 'MISSING_WALLET');

      return {
        status: false,
        transactionHash: null,
        err: errorHandler('MISSING_WALLET'),
      };
    } catch (error) {
      /**
       * Error mapped to the errorMapper: Customer Message to be shown on the UI
       * */
      // console.error(error); // debug
      return {
        status: false,
        transactionHash: null,
        err: error.message,
      };
    }
  };

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line radix
      if (currentChainId !== parseInt(currencies[0].chainId)) {
        try {
          await library.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: currencies[0].chainId,
              },
            ],
          });
        } catch (error) {
          await deactivate();
        }
      }
    })();
  }, [currentChainId]);

  useEffect(() => {
    if (library && account) {
      refreshAccountBalance();
      refreshAccountPayments();
    } else {
      setAccountBalance(0);
      setAccountPayments([]);
    }
  }, [account, library, setAccountBalance, setAccountPayments]);

  useEffect(() => {
    /**
     * Get contract owner and save it
     * */
    if (!contractOwner) {
      if (currentChainId === parseInt(currencies[0].chainId, 10)) {
        if (library) {
          getContractOwner().then((owner) => setContractOwner(owner));
        }
      }
    }
  }, [library, currentChainId, contractOwner, setContractOwner]);

  useEffect(() => {
    if (contractOwner && account && account.toLowerCase() === contractOwner && !isContractOwner) {
      setIsContractOwner(true);
    } else if ((!contractOwner || !account || account.toLowerCase() !== contractOwner) && isContractOwner) {
      setIsContractOwner(false);
    }
  }, [account, isContractOwner, contractOwner, setIsContractOwner]);

  useEffect(() => {
    if (!isWalletConnected && ['/send', '/transactions'].includes(location.pathname)) {
      navigate('/');
    }
  }, [location, navigate, isWalletConnected]);

  return (
    /**
     * ContextProvider with data to be passed down to the various components.
     * */
    <TransactionContext.Provider
      value={{
        accountInfo: {
          isWalletConnected,
          account,
          accountBalance,
          isContractOwner,
        },
        refreshAccountBalance,
        isLoading,
        sendTransactionMutation,
        refreshAccountPayments,
        accountPayments,
        acceptPayment,
        cancelPayment,
        claimProtocolFees,
        errors,
        setIsLoading,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

/**
 * Default Props to be passed to the context provider
 * @children: node
 * */
TransactionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TransactionsProvider;
